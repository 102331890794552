// Gallery

.image-gallery {

    .img-fluid {
        min-width: 100%;
    }

    &.gallery-slider {
        figure {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        &.gallery-grid {
            display: flex;
            flex-wrap: wrap;
            margin-left: -($grid-gutter-width / 2);
            margin-right: -($grid-gutter-width / 2);

            .image-gallery-item {
                padding-left: $grid-gutter-width / 2;
                padding-right: $grid-gutter-width / 2;
                width: calc(100% / 2);

                .image {
                    margin-bottom: $grid-gutter-width;
                }
            }
        }
    }


    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        margin-left: -($grid-gutter-width / 2);
        margin-right: -($grid-gutter-width / 2);

        .image-gallery-item {
            padding-left: $grid-gutter-width / 2;
            padding-right: $grid-gutter-width / 2;

            .image {
                margin-bottom: $grid-gutter-width;
            }

            &.gallery-item-size-2 {
                width: calc(100% / 2);
            }

            &.gallery-item-size-3 {
                width: calc(100% / 3);
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .frame-type-image > .container{
        padding-left: 0;
        padding-right: 0;
        overflow-x: hidden;

        > .frame-header {
            padding-left: 36px;
            padding-right: 36px;
        }
    }
}


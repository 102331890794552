.image-slider {
	.swiper-pagination-wrapper {
		position : relative;

		.swiper-pagination {
			text-align : left;
			bottom     : 60px;
			left       : 8px;

			.swiper-pagination-bullet {
				width         : 30px;
				height        : 20px;
				opacity       : 0.28;
				border-radius : 0;
				position      : relative;
				background    : transparent;

				&.swiper-pagination-bullet-active {
					opacity : 1;
				}

				&:after {
					content          : '';
					position         : absolute;
					height           : 3px;
					width            : 100%;
					top              : 50%;
					left             : 0;
					transform        : translateY(-50%);
					background-color : #FFFFFF;
				}
			}
		}
	}

	.swiper-slide {
		.slide-content-wrapper {
			.slide-content {
				position        : absolute;
				bottom          : 150px;
				display         : flex;
				flex-direction  : column;
				justify-content : flex-start;
				align-items     : flex-start;
				gap             : 35px;
				z-index         : 2;

				.slide-title {
					color       : #FFFFFF;
					font-family : "Mixta Sharp", serif;
					font-size   : 75px;
					font-style  : normal;
					font-weight : 800;
					line-height : 85%; /* 63.75px */
					text-align  : left;
					display     : block;
				}
			}
		}

		.slide-picture {
			display  : flex;
			height   : 100vh;
			position : relative;

			> img {
				width      : 100%;
				object-fit : cover;
				height     : 100%;
			}

			&.darkened {
				filter : brightness(60%) saturate(120%);
			}
		}
	}
}
